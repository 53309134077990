/**
 * JS file for the use with app/view/templates/instant_multiple_files_upload_no_label.html.erb
 * uploads file to server and shows spinner while upload
 *
 * Values to set:
 *  id: same value as in instant_multiple_files_upload_no_label.html.erb
 */

document.addEventListener('turbolinks:load', () => {
    // set base id und url for upload
    const id ="addMediaEntriesArea"
    const tagList = document.getElementById("tagList")

    // init elements
    const input = document.getElementById(`${id}Input`)
    const dropZone = document.getElementById(`${id}DropZone`)

    if (input !== null && dropZone !== null) {
        init()
    }

    function init() {
        // upload via input
        input.addEventListener('change', function (e) {
            let files = videos_to_end(e.target.files)
            file_list_upload(files)
        })

        // highlight drop area green
        dropZone.ondragover = dropZone.ondragenter = function (evt) {
            evt.preventDefault();
            dropZone.classList.add('bg-green-200')
        };

        //remove green highlight of drop area
        dropZone.ondragleave = function (evt) {
            evt.preventDefault();
            dropZone.classList.remove('bg-green-200')
        };

        //upload via drag&drop
        dropZone.ondrop = function (evt) {
            evt.preventDefault();
            let files = videos_to_end(evt.dataTransfer.files)
            file_list_upload(files)
        }
    }

    //upload the files sequentially to avoid Busy Exceptions of the database
    async function file_list_upload(files){
        // get the value of the tag select
        const upload_tags = tagList.getElementsByTagName('input')
        let checkedTags = []
        for (let checkbox of upload_tags) {
            if (checkbox.checked){
                checkedTags.push(checkbox.value)
            }
        }
        let checkedTagsString = checkedTags.join('_')
        if (checkedTagsString === ''){
            checkedTagsString = 'null'
        }

        document.getElementById("uploads_wrapper").classList.remove("hidden")
        //const content_div =  document.getElementById("uploadedContent")

        // show upload spinner
        const uploadSpinner = document.getElementById(`${id}UploadSpinner`)
        const placeholder = document.getElementById(`${id}Placeholder`)
        const uploadText = document.getElementById(`${id}UploadText`)
        const uploadProgress = document.getElementById(`${id}uploadProgress`)
        uploadText.classList.add('hidden')
        uploadSpinner.classList.remove('hidden')
        placeholder.classList.add("hidden")
        uploadProgress.innerHTML = "0%"
        dropZone.classList.remove('bg-green-200')


        // Get the sum of all file. Use in displaying progress
        let total_file_size = 0
        for(let i= 0; i<files.length; i++) {
             total_file_size += files[i].size
        }
        let needed_storage_mb = Math.ceil(total_file_size/1024/1024)

        let response = await check_storage(needed_storage_mb)
        if (!response["enough_storage"]){
            //Cancel Upload if there isn't enough storage, unless the user clicks upload as much as possible
            let cancel = await not_enough_storage(response["available_storage"], needed_storage_mb)
            if (cancel){
                uploadText.classList.remove('hidden')
                uploadSpinner.classList.add('hidden')
                placeholder.classList.remove("hidden")
                return
            }
        }

        //TODO: limit für total_file_size?
        // Verfügbarer Speicher wird auch im model geprüft

        // Sum the already uploaded file sizes
        let uploaded_file_size = 0
        // Use in displaying progress. Different from uploaded size, if there is an error
        let progress_file_size = 0

        for(let i= 0; i<files.length; i++) {
            // Add progress independent of the success of the upload
            if (i !== 0){
                progress_file_size += files[i-1].size
            }
            const type = files[i].type
            let file = files[i]
            // prevent other than jpg, png, mp3, wav and mp4
            if (type !== 'image/png' && type !== 'image/jpeg' && type !== 'audio/mpeg' && type !== 'audio/wav' && type !== 'video/mp4') {
                // Create an error table-row and skip this file
                create_error_entry(file.name, "unsupported", I18n.t('js.wrong_type_alert_all_media'))
                continue
            }
            // prevent files larger then 500 mb
            if (files[i].size/1000000.0 > 500) {
                // Create an error table-row and skip this file
                create_error_entry(file.name, type, I18n.t('js.media_content_max_size_error'))
                continue
            }
            //Prevent AJAX calls if there isn't enough storage for this file. available_storage is in MB, compare it in bytes
            if (!response["enough_storage"] && (uploaded_file_size + files[i].size) > response["available_storage"]*1024*1024){
                // Create an error table-row and skip this file
                create_error_entry(file.name, type, I18n.t('js.not_enough_storage_error'))
                continue
            }

            let upload_successful = true
            await ajaxUpload(file, type, total_file_size, progress_file_size, checkedTagsString).catch((err) => {
                console.error(err);
                upload_successful = false
            });
            //Add the upload size only if the upload was successful
            if (upload_successful){
                //progress of the uploads
                uploaded_file_size += file.size
            }
        }
        uploadText.classList.remove('hidden')
        uploadSpinner.classList.add('hidden')
        placeholder.classList.remove("hidden")

    }

    function ajaxUpload(file, type, total_file_size, progress_file_size, tags) {

        const uploadProgress = document.getElementById(`${id}uploadProgress`)

        // form data from file
        const formData = new FormData();
        formData.append('file_filed', file);

        // type determines upload url
        let url = ''
        if (type === 'image/png' || type === 'image/jpeg') {
            url = `/media_entries/folder_upload/image_create/tag/${tags}`
        } else if (type === 'video/mp4') {
            url = `/media_entries/folder_upload/video_create/tag/${tags}`
        } else if (type === 'audio/mpeg' || type === 'audio/wav') {
            url = `/media_entries/folder_upload/audio_create/tag/${tags}`
        }
        //This case usually gets caught before using the ajaxUpload function
        else {
            create_error_entry(file.name, type, I18n.t('js.wrong_type_alert'))
            return Promise.reject("Wrong media type")
        }

        // do ajax upload
        return new Promise(function (resolve, reject) {
            $.rails.ajax({
                url: url,
                type: "post",
                data: formData,
                async: true,
                cache: false,
                contentType: false,
                enctype: 'multipart/form-data',
                processData: true,
                success: function (data) {
                    resolve(data)
                },
                error: function (response, status) {
                    create_error_entry(file.name, type, "Status "+status)
                    reject(status)
                },
                beforeSend: function (xhr) {
                    // show upload progress
                    xhr.upload.addEventListener('progress', function (e) {
                        const percent_complete = Math.floor(((progress_file_size + e.loaded) / total_file_size) * 100);  // Percentage of upload
                        uploadProgress.innerHTML = `${percent_complete}%`
                    })
                    return true
                }
            })
        })
    }

    // Create a table-row for file entries which returned an error an insert it to the end of the table
    function create_error_entry(filename, type, message){
        let sanitizedType = ""
        if (type === "unsupported") {
            sanitizedType = I18n.t('js.unsupported_type_html')
        } else if (type === 'image/png' || type === 'image/jpeg') {
            sanitizedType = I18n.t('js.image')
        } else if (type === 'audio/mpeg' || type === 'audio/wav') {
            sanitizedType = I18n.t('js.audio')
        } else if (type === 'video/mp4') {
            sanitizedType = I18n.t('js.video')
        }

        let error_html =
                `<tr class="error">
                    <td class="px-6 py-3 w-full whitespace-nowrap text-sm font-medium">
                        <span class="lg:pl-2">${I18n.t('js.error')}: ${message}. ${I18n.t('js.no_media_entry_created')}</span>
                    </td>
                    <td class="px-6 py-3 w-full whitespace-nowrap text-sm font-medium">
                        <span class="lg:pl-2">${I18n.t('js.no_tag')}</span>
                    </td>
                    <td class="px-6 py-3 w-full whitespace-nowrap text-sm font-medium">
                        <span class="lg:pl-2">${sanitizedType}</span>
                    </td>
                    <td class="px-6 py-3 w-full whitespace-nowrap text-sm font-medium">
                        <span class="lg:pl-2">${filename}</span>
                    </td>
                </tr>`
        var contentTable = document.getElementById('contentTable')
        contentTable.insertAdjacentHTML("beforeend", error_html)
    }

    async function check_storage(needed_storage_mb){
        let url = `/media_entries/folder_upload/check_storage/${needed_storage_mb}`
        // do ajax upload
        let storage_result =  new Promise(function (resolve, reject) {
            $.rails.ajax({
                url: url,
                type: "get",
                success: function (data) {
                    resolve(data)
                },
                error: function (response, status) {
                    console.log("Server not available, can't check storage")
                    reject(status)
                },
            })
        })
        return await storage_result
    }

    // ask user if he wants to upload as many files as possible via confirm dialog
    async function not_enough_storage(available_storage, needed_storage){
        let text = I18n.t('js.not_enough_storage_confirm', {available_storage: available_storage,  needed_storage: needed_storage})
        let cancel_upload = true
        if (confirm(text)) {
            cancel_upload = false
        }
        return cancel_upload
    }

    function videos_to_end(file_list){
        let new_file_list = []
        //Add non video files
        for (let i=0; i< file_list.length; i++) {
            if (file_list[i].type !== "video/mp4"){
                new_file_list.push(file_list[i])
            }
        }
        //Add video files
        for (let i=0; i< file_list.length; i++) {
            if (file_list[i].type === "video/mp4"){
                new_file_list.push(file_list[i])
            }
        }
        return new_file_list
    }

})