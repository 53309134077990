document.addEventListener('turbolinks:load', () => {

    const dropZone = document.getElementById('drop_zone');
    const fileInput = document.getElementById('file_input');
    const selectFileText = document.getElementById('added_file');

    const addImageHeading = document.getElementById('add_image_heading');
    const addVideoHeading = document.getElementById('add_video_heading');
    const addAudioHeading = document.getElementById('add_audio_heading');

    const currentMediaSrcHiddenField = document.getElementById('current_media_src_hidden')

    const startUploadButton = document.getElementById('start_upload')
    const fileSelectDiv = document.getElementById('file_select')
    const uploadIndicator = document.getElementById('upload_indicator')

    const jsUpload = document.getElementById('jsUpload')
    const noJsUpload = document.getElementById('noJsUpload')

    const isMediaUpload = ((addImageHeading != null || addVideoHeading != null || addAudioHeading != null) && currentMediaSrcHiddenField != null)
    const isApkUpload = (currentMediaSrcHiddenField == null && addImageHeading == null && addVideoHeading == null && addAudioHeading == null)

    if(dropZone != null && fileInput != null && selectFileText != null &&
        startUploadButton != null && fileSelectDiv != null && uploadIndicator != null &&
        jsUpload !== null && noJsUpload !== null && ( isMediaUpload|| isApkUpload)) {
        init();
    }

    function init() {

        // show js upload hide no js upload
        jsUpload.classList.remove('hidden')
        noJsUpload.classList.add('hidden')

        let type = "";
        if (addImageHeading != null) {
            type = "image";
        } else if (addVideoHeading != null) {
            type = "video";
        } else if (addAudioHeading != null) {
            type = "audio"
        }

        // display current file
        if(isMediaUpload && currentMediaSrcHiddenField.value !== "") {
            selectFileText.innerHTML = I18n.t('js.selected_file') + currentMediaSrcHiddenField.value.replace(/^.*[\\\/]/, '');
        }

        // file input
        fileInput.addEventListener('change', function(e) {
            selectFileText.innerHTML = I18n.t('js.selected_file') + fileInput.value.replace(/^.*[\\\/]/, '');
            if (isMediaUpload) {
                checkStorage(e.target.files[0], type)
            }
        })

        startUploadButton.addEventListener('click', function () {
            fileSelectDiv.classList.add('hidden');
            uploadIndicator.classList.remove('hidden')
        })

        // dropzone
        dropZone.ondragover = dropZone.ondragenter = function(evt) {
            evt.preventDefault();
        };
        dropZone.ondrop = function(evt) {
            evt.preventDefault();

            if (evt.dataTransfer.files.length > 1) {
                alert("You can't drop more then one file");
                return;
            }

            if (type === 'image' && evt.dataTransfer.files[0].type !== 'image/png' && evt.dataTransfer.files[0].type !== 'image/jpeg') {
                //alert("The file has an unsupported type. Please use only png or jpg files");
                alert(I18n.t('js.wrong_type_alert'));
                return;
            } else if (type === 'video' && evt.dataTransfer.files[0].type !== 'video/mp4') {
                //alert("The file has an unsupported type. Please use only mp4 files");
                alert(I18n.t('js.wrong_type_alert_video'));
                return;
            } else if (type === 'audio' && evt.dataTransfer.files[0].type !== 'audio/mpeg' && evt.dataTransfer.files[0].type !== 'audio/wav') {
                //alert("The file has an unsupported type. Please use only mp3 or wav files");
                alert(I18n.t('js.wrong_type_alert_audio'));

                return;
            }

            fileInput.files = evt.dataTransfer.files;
            selectFileText.innerHTML = I18n.t('js.selected_file') + evt.dataTransfer.files[0].name;
            if (isMediaUpload) {
                checkStorage(evt.dataTransfer.files[0], type)
            }
        };
    }

    function checkStorage(file, type) {
        let neededStorage = Math.ceil((file.size/1024/1024));
        console.log(neededStorage)
        $.rails.ajax({
            url: "/organizations/check_storage/" + neededStorage,
            type: "get",
            data: "",
        })
    }
});